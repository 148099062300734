import request from "@/api/index";

/**
 * 盘点分页查询
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getCheckPageList(params) {
  return request({
    url: "/LabCheck/page",
    method: "get",
    params,
  });
}

/**
 * 盘点详情
 *
 * @export
 * @param {*} id
 * @return {*}
 */
export function getCheckDetail(id, code) {
  return request({
    url: `/LabCheck/details/${id}`,
    method: "get",
    params: {
      code,
    },
  });
}

/**
 * 新增盘点
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onAddCheck(data) {
  return request({
    url: "/LabCheck/add",
    method: "post",
    data,
  });
}

/**
 * 操作盘点
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onCheck(data) {
  return request({
    url: "/LabCheck/operation",
    method: "post",
    data,
  });
}

/**
 * 删除盘点
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onDeleteCheck(data) {
  return request({
    url: "/LabCheck/removes",
    method: "post",
    data,
  });
}

export function onAddSku(data) {
  return request({
    url: "/labSku/add",
    method: "post",
    data,
  });
}

export function onAddCategory(data) {
  return request({
    url: "/category/add",
    method: "post",
    data,
  });
}

export function onAddLocation(data) {
  return request({
    url: "/part/location/add",
    method: "post",
    data,
  });
}

export function onAddRoom(data) {
  return request({
    url: "/room/add",
    method: "post",
    data: {
      ...data,
      classify: "LAB",
    },
  });
}

/**
 * category下拉列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getSkuList(params) {
  return request({
    url: "/labSku/list",
    method: "get",
    params,
  });
}

/**
 * category下拉列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getCategoryList(params) {
  return request({
    url: "/category/list",
    method: "get",
    params,
  });
}

/**
 * location下拉列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getLocationList(params) {
  return request({
    url: "/part/location/list",
    method: "get",
    params,
  });
}

/**
 * room下拉列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getRoomList(params) {
  return request({
    url: "/room/list",
    method: "get",
    params: {
      ...params,
      classify: "LAB",
    },
  });
}

/**
 * 分页列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getPageList(params) {
  return request({
    url: "/lab/spare-part/page",
    method: "get",
    params,
  });
}

/**
 * 新增&编辑
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onAdd(data) {
  return request({
    url: "/lab/spare-part/add",
    method: "post",
    data,
  });
}

/**
 * 修改
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onEdit(data) {
  return request({
    url: "/order-food/" + data.id,
    method: "patch",
    data,
  });
}

/**
 * 删除
 *
 * @export
 * @param {*} id
 * @return {*}
 */
export function onDelete(data) {
  return request({
    url: "/lab/spare-part/removes",
    method: "post",
    data,
  });
}

export function uploadFile(data) {
  return request({
    url: "/lab/spare-part/importInfo",
    method: "post",
    data,
  });
}

/**
 * 取消
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function onCancel(data) {
  return request({
    url: "/order-food/cancel",
    method: "post",
    data,
  });
}

/**
 * 导出
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function onExport(params) {
  return request({
    url: "/lab/spare-part/exportTemplate",
    method: "get",
    params,
    responseType: "blob",
  });
}

/**
 * 获取订单详情
 *
 * @export
 * @param {*} id
 * @return {*}
 */
export function getOrderDetail(id) {
  return request({
    url: `/order-food/particulars/${id}`,
    method: "get",
  });
}

/**
 * 根据id查询响应人列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getResponseUserList(params) {
  return request({
    url: "/order-food/getResponsePeoples",
    method: "get",
    params,
  });
}

/**
 * 新增外部人
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function addExternalUser(data) {
  return request({
    url: "/order-food/add",
    method: "post",
    data,
  });
}

/**
 * 新增内部人
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function addInternalUser(data) {
  return request({
    url: "/order-food/add/internal",
    method: "post",
    data,
  });
}

/**
 * 根据id获取所有响应的内部人员
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getIntervalUserIdsByOrderId(params) {
  return request({
    url: "/order-food/allOrderFoodResponseIds",
    methods: "get",
    params,
  });
}

/**
 * 审批是否同意增补
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function updateReviewIncreaseState(data) {
  return request({
    url: "/order-food/increase/examine",
    method: "post",
    data,
  });
}

export function getInventoryInList(params) {
  return request({
    url: "/storageApplication/page",
    method: "get",
    params,
  });
}

export function createInventoryIn(data) {
  return request({
    url: "/storageApplication/add",
    method: "post",
    data,
  });
}

export function deleteInventoryIn(id) {
  return request({
    url: "/storageApplication/delete/" + id,
    method: "post",
  });
}

export function auditInventoryInApply(data) {
  return request({
    url: `/storageApplication/examine`,
    method: "post",
    data,
  });
}

export function editInventoryInCount(id, amount) {
  return request({
    url: `/storageApplication/update/${id}/${amount}`,
    method: "post",
  });
}

export function importInventoryInData(data) {
  return request({
    url: "/storageApplication/importInfo",
    method: "post",
    data,
  });
}
