<template>
  <div
    role="button"
    class="y-button"
    :class="[
      'y-button-' + type,
      {
        loading,
        disabled,
      },
    ]"
    :ghost="ghost"
    :style="{
      width: width + 'px',
    }"
    @click="onClick"
  >
    <LoadingOutlined v-if="loading" style="margin-right: 10px" />
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  width: {
    type: Number,
    default: 80,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "large",
  },
  type: {
    type: String,
    default: "default",
  },
  ghost: {
    type: Boolean,
    default: false,
  },
  // 按钮角色
  role: {
    type: String,
    default: "info",
    validator(value) {
      return [
        // 主按钮，绿色底，白色字，常用于查询、提交等操作
        "primary",
        // 次按钮，灰色底，紫色字，常用于返回、取消、重置等操作
        "info",
      ].includes(value);
    },
  },
});
const emit = defineEmits(["click"]);

const onClick = () => {
  if (props.loading || props.disabled) {
    return;
  }
  emit("click");
};
</script>

<style lang="scss">
.y-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #f8f8fc;
  text-align: center;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  border-radius: 4px;
  &.y-button-primary {
    background-color: var(--primary-color);
  }
  &.y-button-default {
    background-color: #f8f8fc;
    color: var(--primary-color);
  }
}
.y-button[ghost="true"] {
  color: var(--primary-color);
  border-radius: 4px;
  border: 2px solid var(--primary-color);
  background: #fff;
}
.loading {
  opacity: 0.8;
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
