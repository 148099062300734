<template>
  <div class="tanks-card-content-view-box">
    <a-button type="text" v-if="content" @click="handleViewContent"
      >查看</a-button
    >
    <div class="modal-box" v-if="contentVisible">
      <div class="mask" @click="handleCloseModal"></div>
      <div class="card-box">
        <div class="icon-close" @click="handleCloseModal"></div>
        <div class="content-box">
          <div class="title">感谢</div>
          <div class="from">From: {{ from }}</div>
          <div class="to">To: {{ to }}</div>
          <div class="content">{{ content }}</div>
          <div class="footer">
            <div>Sincerely</div>
            <div>{{ from }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThanksCardViewButton",
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    sendToType: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contentVisible: false,
    };
  },
  methods: {
    handleViewContent() {
      this.contentVisible = true;
    },
    handleCloseModal() {
      this.contentVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tanks-card-content-view-box {
  position: relative;
  .modal-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .card-box {
      position: relative;
      z-index: 20;
      width: 1024px;
      height: 800px;
      background: url("@/assets/images/thanks-card.png") no-repeat center center;
      padding-top: 43px;
      padding-left: 112px;
      box-sizing: border-box;
      .icon-close {
        width: 48px;
        height: 48px;
        background: url("@/assets/images/icon-close.png") no-repeat center
          center;
        position: absolute;
        top: 0px;
        right: -60px;
        cursor: pointer;
      }
      .content-box {
        width: 800px;
        height: 600px;
        box-sizing: border-box;
        padding: 40px;
        position: relative;
        text-align: left;
        .title {
          color: #1b1b25;
          font-family: Lato;
          font-weight: 600;
          font-size: 24px;
          line-height: 1.5;
          margin-bottom: 16px;
        }
        .from,
        .to {
          color: #1b1b25;
          font-family: Lato;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }

        .content {
          box-sizing: border-box;
          padding-top: 15px;
          height: 340px;
          overflow-y: auto;
          border-top: 1px dashed #a5cd50;
          margin-top: 16px;
          word-break: break-word;
          .text {
            word-wrap: break-word;
            height: 150px;
            overflow-y: auto;
          }
        }
        .content {
          text-decoration: underline;
          text-decoration-color: #a5cd50;
          text-underline-offset: 3px;
          white-space: wrap;
        }
        .content,
        .footer {
          color: #1b1b25;
          font-family: Lato;
          font-weight: 500;
          font-size: 16px;
          line-height: 2;
        }
        .footer {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
