<template>
  <a-select
    size="large"
    show-search
    :filter-option="filterRoom"
    :value="roomId"
    placeholder="请选择Room"
    :options="
      roomList.map((item) => ({
        value: item?.id,
        label: item?.number,
      }))
    "
  >
  </a-select>
</template>

<script setup>
import { ref, defineProps, defineEmits, defineComponent, onMounted } from "vue";

import { getRoomList } from "@/api/spare-parts";

const props = defineProps({
  groupCode: {
    type: String,
    required: true,
  },
});

const roomId = ref();
const roomList = ref([]);
const roomListSource = ref([]);

const filterRoom = (input, option) => {
  debugger;
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const fetchRoomList = async () => {
  const res = await getRoomList({
    groupCode: props.groupCode,
    labGroup: 1,
  });
  roomList.value = res.data;
  roomListSource.value = res.data;
};

onMounted(() => {
  fetchRoomList();
});
</script>

<style lang="scss" scoped></style>
