<template>
  <a-select
    size="large"
    show-search
    :filter-option="false"
    :value="skuId"
    placeholder="请选择SKU"
    :options="
      skuList.map((item) => ({
        value: item?.id,
        label: item?.name,
      }))
    "
    @search="filterSku"
    @change="handleChangeSku"
  >
    <template #dropdownRender="{ menuNode: menu }">
      <v-nodes-sku :vnodes="menu" />
      <a-divider style="margin: 4px 0" />
      <a-space style="padding: 4px 8px" direction="vertical">
        <div class="flex">
          <div class="text" style="width: 120px">Name：</div>
          <a-input
            ref="newSkuRef"
            v-model:value="newSkuNameValue"
            placeholder="Please enter name"
          />
        </div>
        <div class="flex">
          <div class="text" style="width: 120px">Description：</div>
          <a-input
            v-model:value="newDescriptionValue"
            placeholder="Please enter description"
          />
        </div>
        <div class="flex">
          <div class="text" style="width: 120px">Package：</div>
          <a-input
            v-model:value="newPackageValue"
            placeholder="Please enter package"
          />
        </div>
        <a-button type="primary" @click="handleAddSku">
          <template #icon>
            <plus-outlined />
          </template>
          Add SKU
        </a-button>
      </a-space>
    </template>
  </a-select>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineComponent,
  onMounted,
  watch,
} from "vue";
import { message } from "ant-design-vue";

import {
  onAdd,
  getSkuList,
  getRoomList,
  onAddSku,
  onAddRoom,
} from "@/api/spare-parts";

const emit = defineEmits(["update:value"]);
const props = defineProps({
  groupCode: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
});

const VNodesSku = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

const skuId = ref(props.value || null);
const skuList = ref([]);
const skuListDataSource = ref([]);
const newSkuNameValue = ref();
const newDescriptionValue = ref();
const newPackageValue = ref();
const newSkuValue = ref();
const newSkuRef = ref();

const filterSku = (input) => {
  skuList.value = [...skuListDataSource.value].filter((item) => {
    return item.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  });
};

const handleAddSku = async (e) => {
  if (!newSkuNameValue.value) {
    message.error("请输入 Name");
    return;
  }
  if (!newDescriptionValue.value) {
    message.error("请输入 Description");
    return;
  }
  if (!newPackageValue.value) {
    message.error("请输入 Package");
    return;
  }
  e.preventDefault();
  console.log(newSkuValue.value);
  await onAddSku({
    groupCode: props.groupCode,
    name: newSkuNameValue.value,
    description: newDescriptionValue.value,
    packages: newPackageValue.value,
  });
  fetchSkuList();
  newSkuNameValue.value = null;
  newDescriptionValue.value = null;
  newPackageValue.value = null;
  newSkuRef.value.focus();
};

const fetchSkuList = async () => {
  const res = await getSkuList({
    groupCode: props.groupCode,
  });
  skuList.value = res.data.map((item) => ({
    ...item,
    disabled: item.id == props.value,
  }));
  skuListDataSource.value = res.data.map((item) => ({
    ...item,
    disabled: false,
  }));
};

const handleChangeSku = (value) => {
  skuId.value = value;
  emit("update:value", value);
};

watch(
  () => props.value,
  () => {
    // skuList.value =
  }
);

onMounted(() => {
  fetchSkuList();
});
</script>
<style lang="scss" scoped>
::v-deep {
  .ant-input-status-error {
    border-color: #e1e1ea !important;
  }
}
</style>
