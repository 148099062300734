<template>
  <header class="app-header">
    <div class="left flex">
      <div class="icon-grid" role="button" @mouseenter="onClickGrid">
        <img src="../../assets/images/icon-app.png" />
      </div>
      <div class="site-info">
        <img src="../../assets/images/m-green.png" />
        <div class="site-title">Merck CTS C时办</div>
      </div>
    </div>
    <div class="menus" v-if="menuVisible">
      <div
        class="menu"
        :class="{
          active: activeHeaderMenu && menu.label == activeHeaderMenu.label,
          disabled: !menu.children,
        }"
        v-for="(menu, index) in menuStore.headerMenus"
        :key="index"
        @click="toUrl(menu)"
      >
        {{ formatLabel(menu.label) }}
      </div>
    </div>
    <div class="right" style="flex: 1; justify-content: flex-end">
      <div role="button" class="icon-todo" @click="toTask"></div>
      <!-- <div role="button" class="icon-bell" @click="toTodo">
      </div> -->
      <a-dropdown arrow placement="bottom">
        <div role="button" class="profile">
          <img
            :src="userInfo.portrait"
            class="avatar"
            v-if="userInfo.portrait"
          />
          <img
            src="../../assets/images/header/avatar.png"
            class="avatar"
            v-else
          />
          <div class="username">{{ userInfo?.name || "-" }}</div>
          <div class="icon-down"></div>
        </div>
        <template #overlay>
          <a-menu @click="onClick">
            <a-menu-item key="profile">个人中心</a-menu-item>
            <a-menu-item key="logout">退出登录</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <left-drawer
      v-if="leftDrawerVisible"
      v-model:open="leftDrawerVisible"
      @close="handleClose"
    />
  </header>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { storeToRefs } from "pinia";

import { useMenuStore } from "../../stores/menus";
import { useUserStore } from "../../stores/user";

import { findFirstDeepestNode } from "@/utils/utils";

import LeftDrawer from "./left-drawer.vue";

defineProps({
  menuVisible: {
    type: Boolean,
    default: true,
  },
});

const route = useRoute();
const router = useRouter();

const menuStore = useMenuStore();
const userStore = useUserStore();

const userInfo = userStore.userInfo.userOrg;

const { activeHeaderMenu } = storeToRefs(menuStore);

const routePath = ref("");
const leftDrawerVisible = ref(false);

const formatLabel = (label) => {
  return label ? label.replace("_", " ") : "/";
};

const onClickGrid = () => {
  // message.warning("功能开发中");
  leftDrawerVisible.value = true;
};

const handleClose = () => {
  leftDrawerVisible.value = false;
};

const toUrl = (menu) => {
  console.log(menu);
  if (!menu.children && !menu.url) {
    return;
  }
  menuStore.activeHeaderMenu = menu;
  if (menu.children) {
    if (menu.children.length) {
      const first = findFirstDeepestNode(menu);
      console.log(first);
      router.push(first.url);
      menuStore.activeSideMenu = first;
      // router.push(menu.children[0].url);
    } else {
      message.error("该菜单下没有子菜单");
    }
  } else {
    menuStore.sideMenus = menu.children;
    router.push(menu.url);
    // if (menu.url) {
    // }
  }
};

const onClick = ({ key }) => {
  if (key === "logout") {
    userStore.logout().then(() => {
      message.success("退出成功");
      setTimeout(() => {
        window.location.href = `${process.env.VUE_APP_BASE_URL}/user/login`;
      }, 1000);
    });
  } else if (key == "profile") {
    window.location.href = `${process.env.VUE_APP_BASE_URL}/personal/Index`;
  }
};

const toTask = () => {
  window.location.href = `${process.env.VUE_APP_BASE_URL}/taskCenter/myTask`;
};

// const toTodo = () => {
//   window.location.href = `${process.env.VUE_APP_BASE_URL}/myOrder/toDoList`;
// };

const flattenTree = (tree, key, result = []) => {
  result.push(tree);

  if (tree[key] && tree[key].length > 0) {
    for (const child of tree.children) {
      flattenTree(child, key, result);
    }
  }

  return result;
};

const highlightActiveHeaderMenu = () => {
  const routePath = route.path;
  console.log(routePath);
  console.log(menuStore.headerMenus);
  if (route.path.startsWith("/index/iframe")) {
    const item = menuStore.headerMenus.find((item) => {
      const flatChildren = flattenTree(item, "children");
      return flatChildren?.find((child) => child.url == routePath);
    });
    menuStore.activeHeaderMenu = item;
  } else {
    let item = null;
    if (routePath.startsWith("/index/feedback/QA/feedback-detail")) {
      let type = route.query.type;
      if (type == "5S") {
        type = "6S";
      } else if (type == "Employee Experience") {
        type = "Employee_Experience";
      }
      item = menuStore.headerMenus.find((item) => {
        return item.label == type;
      });
    } else {
      item = menuStore.headerMenus.find((item) => {
        return item.children?.find((child) => {
          return (
            child.url == routePath ||
            routePath.startsWith(child.url.split("?")[0])
          );
        });
      });
    }
    menuStore.activeHeaderMenu = item;
  }
};

watch(
  () => route.path,
  () => {
    routePath.value = route.path;
    console.log(activeHeaderMenu.value);
    highlightActiveHeaderMenu();
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  padding: 0 15px;
  padding-left: 0;
  background: #553096;
  .icon-grid {
    width: 50px;
    height: 50px;
  }
  .site-info {
    display: flex;
    align-items: center;
    margin-right: 24px;
    gap: 16px;
    margin-left: 16px;
    .site-title {
      color: #fff;
      font-family: Futura;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
  }
  .menus {
    display: flex;
    align-items: center;
    height: 50px;
    flex: 1;
    .menu {
      cursor: pointer;
      color: #fff;
      padding: 0 16px;
      height: 100%;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      &.active {
        color: #23183d;
        background: #8a68d2;
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 48px;
    .icon-todo {
      width: 24px;
      height: 24px;
      background: url("../../assets/images/header/icon-todo.png") center
        no-repeat;
    }
    .icon-bell {
      width: 24px;
      height: 24px;
      background: url("../../assets/images/header/icon-bell.png") center
        no-repeat;
      position: relative;
      .count {
        padding: 3px 8px;
        border-radius: 10px 10px 10px 0px;
        background: #eb3c96;
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
        position: absolute;
        right: -19px;
        top: -9px;
      }
    }
    .profile {
      display: flex;
      align-items: center;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      .username {
        color: #fff;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 8px;
      }
      .icon-down {
        width: 16px;
        height: 16px;
        background: url("../../assets/images/header/arrow-down.png") center
          no-repeat;
      }
    }
  }
}
</style>
