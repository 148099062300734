<template>
  <div class="desc-view">
    <a-button type="text" v-if="content" @click="handleViewContent"
      >查看</a-button
    >
    <template v-else>/</template>
    <a-modal
      :title="modalTitle"
      :visible="detailVisible"
      v-model:open="detailVisible"
      :footer="null"
      class="with-bottom-padding"
    >
      <div style="max-height: 500px; overflow-y: auto" v-html="content"></div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalTitle: {
      type: String,
      defaul: "详情",
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      detailVisible: false,
    };
  },
  methods: {
    handleViewContent() {
      this.detailVisible = true;
    },
  },
};
</script>
<style lang=""></style>
