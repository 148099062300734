const menus = [
  {
    label: "Admin",
    icon: require("@/assets/images/home/icon-admin.png"),
    url: "/index/admin",
    children: [
      {
        label: "订餐",
        icon: require("@/assets/images/home/dingcan.png"),
        url: "/index/admin/order-food",
        children: [
          {
            label: "发起订餐",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/admin/order-food/initiate",
            code: "ADMIN-ODM-INIT-IO",
          },
          {
            label: "历史订餐",
            icon: require("@/assets/images/menu/order-food-history.svg"),
            url: "/index/admin/order-food/history",
            code: "ADMIN-ODM-HIST-REC-CHILD",
          },
        ],
      },
      {
        label: "文具",
        icon: require("@/assets/images/home/wenju.png"),
        url: "",
        base: true,
        children: [
          {
            label: "物品信息",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品档案",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsDocument?type=SM`
                )}`,
                code: "ADMIN-SM-ITM-FL",
                name: "/goodsInfo/goodsDocument",
              },
              {
                label: "品牌管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/brand?type=SM`
                )}`,
                code: "ADMIN-SM-ITM-BM",
              },
              {
                label: "物品类型",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsType?type=SM`
                )}`,
                code: "ADMIN-SM-ITM-IT",
              },
              {
                label: "单位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/unit?type=SM`
                )}`,
                code: "ADMIN-SM-ITM-UM",
              },
            ],
          },
          {
            label: "库存管理",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "序列号查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/serialNumber?type=SM`
                )}`,
                code: "ADMIN-SM-INV-SNQ",
              },
              {
                label: "物品入库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/shelfStorage?type=SM`
                )}`,
                code: "ADMIN-SM-INV-IIB",
              },
              {
                label: "物品出库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/goodsRemoval?type=SM`
                )}`,
                code: "ADMIN-SM-INV-IOB",
              },
              {
                label: "库存盘点",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryCheck?type=SM`
                )}`,
                code: "ADMIN-SM-INV-ICK",
              },
              {
                label: "库存数量调整",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryAdjust?type=SM`
                )}`,
                code: "ADMIN-SM-INV-IQA",
              },
            ],
          },
          {
            label: "物品领用",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/goodsSearch?type=SM`
                )}`,
                code: "ADMIN-SM-ISS-ITQ",
              },
              {
                label: "我的物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/myGoods?type=SM`
                )}`,
                code: "ADMIN-SM-ISS-MYI",
              },
              {
                label: "申领物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/pickupGoods?type=SM`
                )}`,
                code: "ADMIN-SM-ISS-APP",
              },
              {
                label: "领取物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/receiveGoods?type=SM`
                )}`,
                code: "ADMIN-SM-ISS-COL",
              },
              {
                label: "归还物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/returnGoods?type=SM`
                )}`,
                code: "ADMIN-SM-ISS-RET",
              },
            ],
          },
          {
            label: "库存看板",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              // {
              //   label: "仪表盘",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Dashboard?type=SM`
              //   )}`,
              //   code: "ADMIN-SM-DSB-DSH",
              // },
              // {
              //   label: "库存汇总",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Summary?type=SM`
              //   )}`,
              //   code: "ADMIN-SM-DSB-SUM",
              // },
              // {
              //   label: "库存明细",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/stockSubsidiary?type=SM`
              //   )}`,
              //   code: "ADMIN-SM-DSB-DET",
              // },
              // {
              //   label: "效期预警",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/ValidityWarning?type=SM`
              //   )}`,
              //   code: "ADMIN-SM-DSB-EXP",
              // },
              {
                label: "库存预警",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/InventoryWarning?type=SM`
                )}`,
                code: "ADMIN-SM-DSB-ALR",
              },
            ],
          },
          {
            label: "管理中心",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "房间管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/houseKeeping?type=SM`
                )}`,
                code: "ADMIN-SM-MNG-RMG",
              },
              {
                label: "设备管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/storageDevice?type=SM`
                )}`,
                code: "ADMIN-SM-MNG-DMG",
              },
              {
                label: "货位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/goodsLocation?type=SM`
                )}`,
                code: "ADMIN-SM-MNG-SMG",
              },
              {
                label: "智能预警规则",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/intelWarningSet?type=SM`
                )}`,
                code: "ADMIN-SM-MNG-WRM",
              },
              {
                label: "系统规则设置",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/sysRulesSet?type=SM`
                )}`,
                code: "ADMIN-SM-MNG-SRM",
              },
            ],
          },
        ],
      },
      {
        label: "礼品",
        icon: require("@/assets/images/home/lipin.png"),
        url: "",
        children: [
          {
            label: "物品信息",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品档案",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsDocument?type=GM`
                )}`,
                code: "ADMIN-GM-ITM-FL",
                name: "/goodsInfo/goodsDocument",
              },
              {
                label: "品牌管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/brand?type=GM`
                )}`,
                code: "ADMIN-GM-ITM-BM",
              },
              {
                label: "物品类型",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsType?type=GM`
                )}`,
                code: "ADMIN-GM-ITM-IT",
              },
              {
                label: "单位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/unit?type=GM`
                )}`,
                code: "ADMIN-GM-ITM-UM",
              },
            ],
          },
          {
            label: "库存管理",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "序列号查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/serialNumber?type=GM`
                )}`,
                code: "ADMIN-GM-INV-SNQ",
              },
              {
                label: "物品入库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/shelfStorage?type=GM`
                )}`,
                code: "ADMIN-GM-INV-IIB",
              },
              {
                label: "物品出库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/goodsRemoval?type=GM`
                )}`,
                code: "ADMIN-GM-INV-IOB",
              },
              {
                label: "库存盘点",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryCheck?type=GM`
                )}`,
                code: "ADMIN-GM-INV-ICK",
              },
              {
                label: "库存数量调整",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryAdjust?type=GM`
                )}`,
                code: "ADMIN-GM-INV-IQA",
              },
            ],
          },
          {
            label: "物品领用",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/goodsSearch?type=GM`
                )}`,
                code: "ADMIN-GM-ISS-ITQ",
              },
              {
                label: "我的物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/myGoods?type=GM`
                )}`,
                code: "ADMIN-GM-ISS-MYI",
              },
              {
                label: "申领物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/pickupGoods?type=GM`
                )}`,
                code: "ADMIN-GM-ISS-APP",
              },
              {
                label: "领取物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/receiveGoods?type=GM`
                )}`,
                code: "ADMIN-GM-ISS-COL",
              },
              {
                label: "归还物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/returnGoods?type=GM`
                )}`,
                code: "ADMIN-GM-ISS-RET",
              },
            ],
          },
          {
            label: "库存看板",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              // {
              //   label: "仪表盘",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Dashboard?type=GM`
              //   )}`,
              //   code: "ADMIN-GM-DSB-DSH",
              // },
              // {
              //   label: "库存汇总",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Summary?type=GM`
              //   )}`,
              //   code: "ADMIN-GM-DSB-SUM",
              // },
              // {
              //   label: "库存明细",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/stockSubsidiary?type=GM`
              //   )}`,
              //   code: "ADMIN-GM-DSB-DET",
              // },
              // {
              //   label: "效期预警",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/ValidityWarning?type=GM`
              //   )}`,
              //   code: "ADMIN-GM-DSB-EXP",
              // },
              {
                label: "库存预警",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/InventoryWarning?type=GM`
                )}`,
                code: "ADMIN-GM-DSB-ALR",
              },
            ],
          },
          {
            label: "管理中心",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "房间管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/houseKeeping?type=GM`
                )}`,
                code: "ADMIN-GM-MNG-RMG",
              },
              {
                label: "设备管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/storageDevice?type=GM`
                )}`,
                code: "ADMIN-GM-MNG-DMG",
              },
              {
                label: "货位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/goodsLocation?type=GM`
                )}`,
                code: "ADMIN-GM-MNG-SMG",
              },
              {
                label: "智能预警规则",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/intelWarningSet?type=GM`
                )}`,
                code: "ADMIN-GM-MNG-WRM",
              },
              {
                label: "系统规则设置",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/sysRulesSet?type=GM`
                )}`,
                code: "ADMIN-GM-MNG-SRM",
              },
            ],
          },
        ],
      },
      {
        label: "IT备品备件",
        icon: require("@/assets/images/home/lipin.png"),
        url: "/index/admin/it/device-list",
        children: [
          {
            label: "物品查询",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/admin/it/device-list",
            code: "ADMIN-ITM-CTMITQ",
          },
        ],
      },
    ],
  },
  {
    label: "Facility",
    icon: require("@/assets/images/home/icon-facility.png"),
    url: "/index/facility",
    children: [
      {
        label: "库存",
        icon: require("@/assets/images/home/kucun.png"),
        url: "",
        children: [
          {
            label: "物品信息",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品档案",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsDocument?type=IVM`
                )}`,
                code: "FACILITY-IVM-ITM-FL",
                name: "/goodsInfo/goodsDocument",
              },
              {
                label: "品牌管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/brand?type=IVM`
                )}`,
                code: "FACILITY-IVM-ITM-BM",
              },
              {
                label: "物品类型",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/goodsType?type=IVM`
                )}`,
                code: "FACILITY-IVM-ITM-IT",
              },
              {
                label: "单位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsInfo/unit?type=IVM`
                )}`,
                code: "FACILITY-IVM-ITM-UM",
              },
            ],
          },
          {
            label: "库存管理",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "序列号查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/serialNumber?type=IVM`
                )}`,
                code: "FACILITY-IVM-INV-SNQ",
              },
              {
                label: "物品入库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/shelfStorage?type=IVM`
                )}`,
                code: "FACILITY-IVM-INV-IIB",
              },
              {
                label: "物品出库",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/goodsRemoval?type=IVM`
                )}`,
                code: "FACILITY-IVM-INV-IOB",
              },
              {
                label: "库存盘点",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryCheck?type=IVM`
                )}`,
                code: "FACILITY-IVM-INV-ICK",
              },
              {
                label: "库存数量调整",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/inventory/inventoryAdjust?type=IVM`
                )}`,
                code: "FACILITY-IVM-INV-IQA",
              },
            ],
          },
          {
            label: "物品领用",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "物品查询",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/goodsSearch?type=IVM`
                )}`,
                code: "FACILITY-IVM-ISS-ITQ",
              },
              {
                label: "我的物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/myGoods?type=IVM`
                )}`,
                code: "FACILITY-IVM-ISS-MYI",
              },
              {
                label: "申领物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/pickupGoods?type=IVM`
                )}`,
                code: "FACILITY-IVM-ISS-APP",
              },
              {
                label: "领取物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/receiveGoods?type=IVM`
                )}`,
                code: "FACILITY-IVM-ISS-COL",
              },
              {
                label: "归还物品",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/goodsClaim/returnGoods?type=IVM`
                )}`,
                code: "FACILITY-IVM-ISS-RET",
              },
            ],
          },
          {
            label: "库存看板",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              // {
              //   label: "仪表盘",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Dashboard?type=IVM`
              //   )}`,
              //   code: "FACILITY-IVM-DSB-DSH",
              // },
              // {
              //   label: "库存汇总",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/Summary?type=IVM`
              //   )}`,
              //   code: "FACILITY-IVM-DSB-SUM",
              // },
              // {
              //   label: "库存明细",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/stockSubsidiary?type=IVM`
              //   )}`,
              //   code: "FACILITY-IVM-DSB-DET",
              // },
              // {
              //   label: "效期预警",
              //   url: `/index/iframe/${encodeURIComponent(
              //     `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/ValidityWarning?type=IVM`
              //   )}`,
              //   code: "FACILITY-IVM-DSB-EXP",
              // },
              {
                label: "库存预警",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/InventoryBoard/InventoryWarning?type=IVM`
                )}`,
                code: "FACILITY-IVM-DSB-ALR",
              },
            ],
          },
          {
            label: "管理中心",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/iframe",
            children: [
              {
                label: "房间管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/houseKeeping?type=IVM`
                )}`,
                code: "FACILITY-IVM-MNG-RMG",
              },
              {
                label: "设备管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/storageDevice?type=IVM`
                )}`,
                code: "FACILITY-IVM-MNG-DMG",
              },
              {
                label: "货位管理",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/goodsLocation?type=IVM`
                )}`,
                code: "FACILITY-IVM-MNG-SMG",
              },
              {
                label: "智能预警规则",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/intelWarningSet?type=IVM`
                )}`,
                code: "FACILITY-IVM-MNG-WRM",
              },
              {
                label: "系统规则设置",
                url: `/index/iframe/${encodeURIComponent(
                  `${process.env.VUE_APP_INVENTORY_URL}/manager/sysRulesSet?type=IVM`
                )}`,
                code: "FACILITY-IVM-MNG-SRM",
              },
            ],
          },
        ],
      },
      {
        label: "工单",
        icon: require("@/assets/images/home/gongdan.png"),
        url: "/index/iframe",
        children: [
          {
            label: "工单列表",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/repairManage/workOrderList?type=WO`
            )}`,
            code: "FACILITY-WO-ODL",
          },
          {
            label: "计划服务",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/repairManage/planService?type=WO`
            )}`,
            code: "FACILITY-WO-PLS",
          },
          // {
          //   label: "工单统计",
          //   icon: require("@/assets/images/menu/clean-items-settings.svg"),
          //   url: `/index/iframe/${encodeURIComponent(
          //     `${process.env.VUE_APP_ASSET_URL}/statistical/orderStatistical?type=WO`
          //   )}`,
          //   code: "FACILITY-WO-SUM",
          // },
        ],
      },
      {
        label: "设备设施",
        icon: require("@/assets/images/home/shebeisheshi.png"),
        url: "/index/iframe",
        children: [
          // {
          //   label: "资产统计",
          //   icon: require("@/assets/images/menu/clean-items-settings.svg"),
          //   url: `/index/iframe/${encodeURIComponent(
          //     `${process.env.VUE_APP_ASSET_URL}/statistical/deviceStatistical?type=FS`
          //   )}`,
          //   code: "FACILITY-FS-AST",
          // },
          {
            label: "设备台账",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/assetsManage/deviceLedger?type=FS`
            )}`,
            code: "FACILITY-FS-EQ",
          },
          {
            label: "备件管理",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/repairManage/spairManage?type=FS`
            )}`,
            code: "FACILITY-FS-SP",
          },
        ],
      },
      {
        label: "合同",
        icon: require("@/assets/images/home/hetong.png"),
        url: "/index/iframe",
        children: [
          // {
          //   label: "维保合同统计",
          //   icon: require("@/assets/images/menu/clean-items-settings.svg"),
          //   url: `/index/iframe/${encodeURIComponent(
          //     `${process.env.VUE_APP_ASSET_URL}/statistical/contractStatistical?type=CT`
          //   )}`,
          //   code: "FACILITY-CT-MCS",
          // },
          {
            label: "维保合同",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/repairManage/contract?type=CT`
            )}`,
            code: "FACILITY-CT-MC",
          },
        ],
      },
      {
        label: "能源",
        icon: require("@/assets/images/home/nengyuan.png"),
        url: "/index/facility/energy",
        children: [
          {
            label: "能耗统计",
            icon: require("@/assets/images/menu/energy-stat.svg"),
            url: "/index/facility/energy/statistics",
            code: "FACILITY-EG-ECS",
          },
          {
            label: "能耗配置",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/facility/energy/config",
            code: "FACILITY-EG-EC",
          },
        ],
      },
      {
        label: "电子巡检",
        icon: require("@/assets/images/home/nengyuan.png"),
        url: "/index/facility/inspection",
        children: [
          {
            label: "巡检分类",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/facility/inspection/classify",
            code: "page:inspection:admin",
          },
          {
            label: "巡检项",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/facility/inspection/items",
            code: "page:inspection:admin",
          },
          {
            label: "巡检设备",
            icon: require("@/assets/images/menu/energy-stat.svg"),
            url: "/index/facility/inspection/device",
            code: "page:inspection:admin",
          },
          // {
          //   label: "巡检配置",
          //   icon: require("@/assets/images/menu/energy-config.svg"),
          //   url: "/index/facility/inspection/config",
          //   code: "FACILITY-EG-EC",
          // },
          {
            label: "巡检记录",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/facility/inspection/record",
            code: "page:inspection:admin",
          },
        ],
      },
    ],
  },
  {
    label: "VC/VP",
    icon: require("@/assets/images/home/icon-vc-vp.png"),
    url: "/index/vc/vp",
    children: [
      {
        label: "仪器",
        icon: require("@/assets/images/home/yiqi.png"),
        url: "/index/iframe",
        children: [
          {
            label: "预约统计",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/statistical/appointStatistical?type=INS`
            )}`,
            code: "VCVP-INS-RS",
          },
          {
            label: "发起预约",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/appoint/appointStart?type=INS`
            )}`,
            code: "VCVP-INS-IR",
          },
          {
            label: "预约审批",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/appoint/appointApproval?type=INS`
            )}`,
            code: "VCVP-INS-RA",
          },
          {
            label: "预约管理",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/appoint/appointManage?type=INS`
            )}`,
            code: "VCVP-INS-RM",
          },
          // {
          //   label: "资产统计",
          //   icon: require("@/assets/images/menu/clean-items-settings.svg"),
          //   url: `/index/iframe/${encodeURIComponent(
          //     `${process.env.VUE_APP_ASSET_URL}/statistical/deviceStatistical?type=INS`
          //   )}`,
          //   code: "VCVP-INS-AS",
          // },
          {
            label: "设备台账",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/assetsManage/deviceLedger?type=INS`
            )}`,
            code: "VCVP-INS-EL",
          },
          {
            label: "共享设备",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: `/index/iframe/${encodeURIComponent(
              `${process.env.VUE_APP_ASSET_URL}/appoint/appointDevice?type=INS`
            )}`,
            code: "VCVP-INS-SE",
          },
        ],
      },
      {
        label: "值日",
        icon: require("@/assets/images/home/zhiri.png"),
        url: "/index/vc/vp/on-duty",
        children: [
          {
            label: "值日排期",
            icon: require("@/assets/images/menu/duty-schedule.svg"),
            url: "/index/vc/vp/on-duty/duty",
            code: "VCVP-DTY-SM",
          },
          {
            label: "设备清洁计划",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: "/index/vc/vp/on-duty/clean-plan",
            code: "VCVP-DTY-CC",
          },
          {
            label: "值日调整审核",
            icon: require("@/assets/images/menu/duty-adjustment-review.svg"),
            url: "/index/vc/vp/on-duty/duty-adjustment-review",
            code: "VCVP-DTY-CA",
          },
          {
            label: "值日记录",
            icon: require("@/assets/images/menu/clean.svg"),
            url: "/index/vc/vp/on-duty/duty-record",
            code: "VCVP-DTY-DR",
          },
          {
            label: "清洁状态修改记录",
            icon: require("@/assets/images/menu/duty-record.svg"),
            url: "/index/vc/vp/on-duty/change-records",
            code: "Lab-DTY-ZTXG",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "清洁项",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/vc/vp/on-duty/clean-items",
            code: "Lab-DTY-QJXM",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "清洁类型",
            icon: require("@/assets/images/menu/check-in-config.svg"),
            url: "/index/vc/vp/on-duty/clean-items-type",
            code: "Lab-DTY-QJLXM",
            // code: "VCVP-DTY-DR",
          },
        ],
      },
      {
        label: "备品备件",
        icon: require("@/assets/images/home/beipinbeijian.png"),
        url: "/index/vc/vp/spare/device-list",
        children: [
          {
            label: "库存查询",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/spare/device-list",
            code: "LAB-SP-KCCX",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "库存盘点",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/spare/device-list/inventory-check",
            code: "LAB-SP-KCGL",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "申领",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/spare/device-list/application",
            code: "page:apply",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "申领记录",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/spare/device-list/application-record",
            code: "page:apply",
            // code: "VCVP-DTY-DR",
          },
          // {
          //   label: "我的入库",
          //   icon: require("@/assets/images/menu/order-food-initiate.svg"),
          //   url: "/index/vc/vp/spare/device-list/inventory-in",
          //   code: "LAB-SP-KCCX",
          //   // code: "VCVP-DTY-DR",
          // },
          // {
          //   label: "入库审核",
          //   icon: require("@/assets/images/menu/order-food-initiate.svg"),
          //   url: "/index/vc/vp/spare/device-list/inventory-in-audit",
          //   code: "LAB-SP-KCGL",
          //   // code: "VCVP-DTY-DR",
          // },
        ],
      },
      {
        label: "管理中心",
        icon: require("@/assets/images/home/beipinbeijian.png"),
        url: "/index/vc/vp/management-center",
        children: [
          {
            label: "实验组",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/management-center/lab-group",
            code: "VCVP-MNG-SYZM",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "房间",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/management-center/room-list",
            code: "VCVP-MNG-ROOMM",
            // code: "VCVP-DTY-DR",
          },
          {
            label: "房间SKU申领模板",
            icon: require("@/assets/images/menu/order-food-initiate.svg"),
            url: "/index/vc/vp/management-center/sku-template",
            code: "VCVP-MNG-ROOMM",
            // code: "VCVP-DTY-DR",
          },
        ],
      },
    ],
  },
  {
    label: "EAG",
    icon: require("@/assets/images/home/icon-eag.png"),
    url: "/index/EAG",
    children: [
      {
        label: "运动打卡",
        icon: require("@/assets/images/home/yundongdaka.png"),
        url: "/index/EAG/exercise-check-in",
        children: [
          {
            label: "员工打卡审核",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/exercise-check-in/review",
            code: "EAG-FC-CA",
          },
          {
            label: "员工打卡记录",
            icon: require("@/assets/images/menu/check-in-records.svg"),
            url: "/index/EAG/exercise-check-in/record",
            code: "EAG-FC-CR",
          },
          // {
          //   label: "打卡奖励",
          //   icon: require("@/assets/images/menu/check-in-award.svg"),
          //   url: "/index/EAG/exercise-check-in/award",
          //   code: "EAG-FC-CRW",
          // },
          {
            label: "打卡配置",
            icon: require("@/assets/images/menu/check-in-config.svg"),
            url: "/index/EAG/exercise-check-in/config",
            code: "EAG-FC-CC",
          },
          {
            label: "活动管理",
            icon: require("@/assets/images/menu/check-in-config.svg"),
            url: "/index/EAG/exercise-check-in/activity",
            code: "page:group:admin",
          },
          {
            label: "我的团队打卡",
            icon: require("@/assets/images/menu/check-in-config.svg"),
            url: "/index/EAG/exercise-check-in/activity-history",
            code: "page:group:user",
          },
        ],
      },
      {
        label: "员工感谢",
        icon: require("@/assets/images/home/yuangongganxie.png"),
        url: "/index/EAG/appreciate",
        children: [
          {
            label: "感谢公开审核",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/appreciate/review",
            code: "EAG-EA-AA",
          },
          {
            label: "历史感谢",
            icon: require("@/assets/images/menu/check-in-records.svg"),
            url: "/index/EAG/appreciate/record",
            code: "EAG-EA-AR",
          },
          {
            label: "发布感谢",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: "/index/EAG/appreciate/create",
            code: "page:my_thanks",
          },
          {
            label: "我的感谢",
            icon: require("@/assets/images/menu/clean-items-settings.svg"),
            url: "/index/EAG/appreciate/mine",
            code: "page:my_thanks",
          },
          // {
          //   label: '感谢统计',
          //   icon: 'check-in-award'
          //   // url: '/index/EAG/appreciate/stat'
          // }
        ],
      },
      {
        label: "调查问卷",
        icon: require("@/assets/images/home/diaochawenjuan.png"),
        url: "/index/EAG/qa",
        children: [
          {
            label: "问卷管理",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/qa/question-list",
            code: "EAG-SVY-SM",
          },
        ],
      },
      {
        label: "大屏配置",
        icon: require("@/assets/images/home/diaochawenjuan.png"),
        url: "/index/EAG/stat",
        children: [
          {
            label: "大屏配置",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/stat/config",
            code: "EAG-SVY-SM-STAT",
            // code: "EAG-SVY-SM",
          },
          {
            label: "生日角配置",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/stat/birthday",
            code: "page:stat_birthday_config",
          },
        ],
      },
      {
        label: "抽奖",
        icon: require("@/assets/images/home/diaochawenjuan.png"),
        url: "/index/EAG/lottery",
        children: [
          {
            label: "抽奖配置",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/lottery/config",
            code: "page:lottery_config",
          },
          {
            label: "抽奖列表",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/lottery/list",
            code: "page:lottery_record",
          },
        ],
      },
      {
        label: "积分商城",
        icon: require("@/assets/images/home/diaochawenjuan.png"),
        url: "/index/EAG/store",
        children: [
          {
            label: "商品管理",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/store/products",
            code: "page:store:admin",
          },
          {
            label: "积分管理",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/store/points",
            code: "page:store:admin",
          },
          {
            label: "兑换申请管理",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/store/exchange-apply",
            code: "page:store:admin",
          },
          {
            label: "商品兑换",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/store/exchange",
            code: "page:store:user",
          },
          {
            label: "我的积分",
            icon: require("@/assets/images/menu/check-in-review.svg"),
            url: "/index/EAG/store/my-points",
            code: "page:store:user",
          },
        ],
      },
    ],
  },
  {
    label: "Feedback",
    icon: require("@/assets/images/home/icon-feedback.png"),
    url: "/index/feedback",
    children: [
      {
        label: "EHS",
        icon: require("@/assets/images/home/ehs.png"),
        url: "/index/feedback/QA/EHS",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/EHS",
            code: "EAG-EHS-FH",
          },
          {
            label: "区域管理",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/feedback/QA/EHS/area-list/EHS",
            code: "EAG-EHS-AREA",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/EHS/operator/EHS",
            code: "page:EHS:operator_config",
          },
        ],
      },
      {
        label: "QA",
        icon: require("@/assets/images/home/qa.png"),
        url: "/index/feedback/QA/QA",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/QA",
            code: "EAG-QA-FH",
          },
          {
            label: "处理人配置",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/feedback/QA/QA/area-list/QA",
            code: "EAG-QA-AREA",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/QA/operator/QA",
            code: "page:QA:operator_config",
          },
        ],
      },
      {
        label: "6S",
        icon: require("@/assets/images/home/5s.png"),
        url: "/index/feedback/QA/5S",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/5S",
            code: "EAG-5S-FH",
          },
          {
            label: "区域管理",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/feedback/QA/5S/area-list/5S",
            code: "EAG-5S-AREA",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/5S/operator/5S",
            code: "page:6S:operator_config",
          },
        ],
      },
      {
        label: "Employee_Experience",
        icon: require("@/assets/images/home/employee.png"),
        url: "/index/feedback/QA/Employee_Experience",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Employee_Experience",
            code: "EAG-EMP-FH",
          },
          {
            label: "处理人配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Employee_Experience/area-list/Employee_Experience",
            code: "EAG-EMP-MNG",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Employee_Experience/operator/Employee_Experience",
            code: "page:Employee_Experience:operator_config",
          },
        ],
      },
      {
        label: "Others",
        icon: require("@/assets/images/home/others.png"),
        url: "/index/feedback/QA/Others",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Others",
            code: "EAG-OTH-FH",
          },
          {
            label: "处理人配置",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/feedback/QA/Others/area-list/Others",
            code: "EAG-OTH-MNG",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Others/operator/Others",
            code: "page:Others:operator_config",
          },
        ],
      },
      {
        label: "Sustainability",
        icon: require("@/assets/images/home/sus.png"),
        url: "/index/feedback/QA/Sustainability",
        children: [
          {
            label: "反馈查询",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Sustainability",
            code: "EAG-SBT-FH",
          },
          {
            label: "处理人配置",
            icon: require("@/assets/images/menu/energy-config.svg"),
            url: "/index/feedback/QA/Sustainability/area-list/Sustainability",
            code: "EAG-SBT-MNG",
          },
          {
            label: "反馈处理配置",
            icon: require("@/assets/images/menu/feedback-query.svg"),
            url: "/index/feedback/QA/Sustainability/operator/Sustainability",
            code: "page:Sustainability:operator_config",
          },
        ],
      },
    ],
  },
];

export default menus;
