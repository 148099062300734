import axios from "axios";
// import Cookies from "js-cookie";

import { message } from "ant-design-vue";

import { getToken } from "@/utils/token";

// import { useUserStore } from "@/stores/user";

const errorKey = "errorKey";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  baseURL:
    process.env.NODE_ENV == "development"
      ? "/"
      : process.env.VUE_APP_API_BASE_URL,
  timeout: 1000 * 30,
});

instance.interceptors.request.use((config) => {
  const excludeTokenUrls = [
    "/api/labbase/v1/auth/login/account",
    "/api/custom/shipu/login",
  ];

  // if (process.env.NODE_ENV == "development") {
  //   if (config.url.startsWith("/api")) {
  //     config.baseURL = "";
  //   } else {
  //     config.baseURL = "/api/custom";
  //   }
  // }
  // if (process.env.NODE_ENV == "production") {
  //   console.log();
  // }
  if (config.url.startsWith("/api")) {
    config.baseURL = "";
  } else {
    // if (
    //   process.env.NODE_ENV == "build-prod" ||
    //   process.env.NODE_ENV == "build-uat"
    // ) {
    config.baseURL = "/api/custom";
    // } else {
    //   config.baseURL = `${process.env.VUE_APP_API_BASE_URL}/api/custom`;
    // }
  }

  // const userStore = useUserStore();
  // config.headers["UserId"] = userStore?.userInfo?.userOrg?.id;
  // if (store.state.userInfo && store.state.userInfo.userOrg) {
  // }
  // if (config.url.startsWith("/bp")) {
  //   config.url.replace("/bp", "");
  //   // config.baseURL = process.env.VUE_APP_LAB_BASE_URL;
  //   config.baseURL = "";
  // }
  // const labUrls = ['api/base', 'api/labbase']
  // if (
  //   labUrls.some(
  //     (url) =>
  //       config.url.indexOf(url) > -1 &&
  //       config.url.indexOf('auth/login/account') == -1
  //   )
  // ) {
  //   config.baseURL = import.meta.env.VUE_APP_LAB_BASE_URL
  // }
  if (excludeTokenUrls.every((url) => url.indexOf(config.url) == -1)) {
    const token = getToken();
    config.headers["x-token"] = `Bearer ${token}`;
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  config.headers["Accept-Language"] = "zh";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (!response.data.success && response.data.code == "API_REFUSED") {
      message.error({
        content: response.data.message,
        key: errorKey,
      });
      return Promise.reject(response.data);
    }
    if (response.config.url.indexOf("labbase") > -1) {
      if (response.data.success) {
        return Promise.resolve(response.data.data);
      } else {
        message.error({
          content: response.data.debugMessage,
          key: errorKey,
        });
        return Promise.reject(response.data);
      }
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    console.error(error);
    if (
      (error.response && error.response?.status == 401) ||
      error.response?.status == 403
    ) {
      message.error({
        content: error.response.data.message,
        key: errorKey,
      });
      // setTimeout(() => {
      //   window.location.href = `${process.env.VUE_APP_BASE_URL}/user/login`;
      // }, 2000);
      return Promise.reject(error.response.data);
    }

    if (error.response && error.response.data) {
      message.error({
        content: error.response.data.message,
        key: errorKey,
      });
    } else {
      message.error({
        content: error.message || "操作失败，请稍后重试",
        key: errorKey,
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
