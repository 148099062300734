import dayjs from "dayjs";

const formatTime = (datetime, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!datetime) {
    return "/";
  }
  return dayjs(datetime).format(format);
};

/**
 * 导出文件
 *
 * @param {*} obj 文件流
 * @param {*} name 文件名
 * @param {*} suffix 文件后缀
 */
const downloadFile = (obj, name, suffix) => {
  const url = window.URL.createObjectURL(new Blob([obj]));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  const fileName = name + "." + suffix;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 计算两个时间的时间差
 *
 * @param {*} start
 * @param {*} end
 * @return {*}
 */
const calculateTimeDifference = (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);

  const timeDifference = Math.abs(endTime - startTime) / 1000; // 时间差的秒数

  const hours = Math.floor(timeDifference / 3600); // 计算小时
  const minutes = Math.floor((timeDifference % 3600) / 60); // 计算分钟
  const seconds = Math.floor(timeDifference % 60); // 计算秒

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

/**
 * 是否在某个时间之前
 *
 * @param {*} targetTime
 * @param {*} referenceTime
 * @return {*}
 */
const isBeforeTime = (targetTime, referenceTime) => {
  const targetDate = new Date(targetTime);
  const referenceDate = new Date(referenceTime);

  return targetDate < referenceDate;
};

const findFirstDeepestNode = (node, key = "children") => {
  if (!node[key] || node[key].length === 0) {
    return node; // 找到最底层的第一个值
  } else {
    return findFirstDeepestNode(node[key][0]);
  }
};

export function convertTreeToList(tree, children = "children") {
  let list = [];

  function traverse(node) {
    list.push(node);

    if (node[children] && node[children].length > 0) {
      for (let child of node[children]) {
        traverse(child);
      }
    }
  }

  traverse(tree);
  return list;
}

export function renderAWSImage(key) {
  return `${process.env.VUE_APP_IMAGE_DOWNLOAD_URL}/${key}`;
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export const debounce = function (func, wait = 1000, immediate = false) {
  let timer;
  console.log(1);
  return function () {
    console.log(123);
    let context = this,
      args = arguments;
    if (timer) clearTimeout(timer);
    if (immediate) {
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timer = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
};

export {
  formatTime,
  downloadFile,
  calculateTimeDifference,
  isBeforeTime,
  findFirstDeepestNode,
};
