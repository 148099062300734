<template>
  <a-tree-select
    size="large"
    style="width: 100%"
    :allowClear="allowClear"
    :placeholder="placeholder"
    :tree-data="treeData"
    :maxTagCount="maxTagCount"
    :treeCheckable="treeCheckable"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :field-names="{
      children: 'children',
      label: 'name',
      value: 'id',
    }"
    @change="handleChange"
    @treeExpand="onLoadData"
  ></a-tree-select>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, unref } from "vue";
import { message } from "ant-design-vue";
import { getDepartmentList, getUsersByDepartment } from "@/api/user";

import { convertTreeToList } from "@/utils/utils";

const props = defineProps({
  treeCheckable: {
    type: Boolean,
    default: true,
  },
  allowClear: {
    type: Boolean,
    default: false,
  },
  maxTagCount: {
    type: Number,
  },
  placeholder: {
    type: String,
    default: "请选择负责人",
  },
  onlyChildCheckable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:value", "change", "init"]);

const treeData = ref([]);
const listTreeData = ref([]);

const fetchTreeData = async () => {
  const { data } = await getDepartmentList();
  const list = convertTreeToList(data[0]);
  listTreeData.value = [...list];
  const promiseList = list.map((item) =>
    getUsersByDepartment({
      pageNum: 1,
      pageSize: 1000,
      departmentId: item.id,
    })
  );
  const resList = await Promise.all(promiseList);
  treeData.value = resList.map((res, index) => {
    return {
      ...list[index],
      children: res.records,
    };
  });
  emit("init", unref(treeData.value).flat());
};

const onLoadData = async (treeNode) => {
  try {
    if (!treeNode.length) {
      return;
    }
    const res = await getUsersByDepartment({
      pageNum: 1,
      pageSize: 1000,
      departmentId: treeNode[treeNode.length - 1],
    });
    treeData.value.flat().forEach((item) => {
      if (item.id == treeNode[treeNode.length - 1]) {
        item.children = res.records.map((item) => {
          return {
            ...item,
            children: [],
            isLeaf: true,
          };
        });
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const handleChange = (value, label, extra) => {
  console.log(extra.triggerNode);
  console.log(value, label, extra);
  if (!props.treeCheckable && props.onlyChildCheckable) {
    const item = treeData.value.find((item) => item.id == value);
    console.log(item);
    if (item) {
      message.error("请选择人员");
      return;
    }
    emit("update:value", value);
    emit("change", {
      value: [value],
      label,
      extra,
    });
  } else {
    let userNum = null;
    if (Array.isArray(value)) {
      userNum = value.map((id) => {
        const user = [...treeData.value]
          .map((item) => {
            return convertTreeToList(item);
          })
          .flat()
          .find((item) => item.id == id);
        const num = user.userNum;
        return num;
      });
    } else {
      const user = [...treeData.value]
        .map((item) => {
          return convertTreeToList(item);
        })
        .flat()
        .find((item) => item.id == value);
      userNum = user.userNum;
    }
    emit("update:value", value);
    emit("change", {
      value,
      label,
      extra,
      userNum,
    });
  }
};

onMounted(() => {
  fetchTreeData();
});
</script>
